import { useFormikContext } from 'formik'
import React, { ChangeEvent } from 'react'
import { RemarkFormValues } from '../../RemarkFormDrawer'


export const FileUploader = () => {

  const { values, setFieldValue } = useFormikContext<RemarkFormValues>()
  const { fileList, uploadedFiles } = values

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filesArray = e.target.files
    if (filesArray) {
      const existingFiles = fileList ? Array.from(fileList) : []
      const newFiles = Array.from(filesArray).filter(file => file.size <= 100 * 1024 * 1024)
      const uploadedFilesLenght = uploadedFiles?.length || 0

      if (existingFiles.length + newFiles.length + uploadedFilesLenght > 5) {
        return
      }

      const dataTransfer = new DataTransfer()
      existingFiles.forEach(file => dataTransfer.items.add(file))
      newFiles.forEach(file => dataTransfer.items.add(file))

      const filesList = dataTransfer.files
      setFieldValue('fileList', filesList)
      e.target.value = ''
    }
  }

  return (
    <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} />
  )
}
