import { Divider, Drawer, Radio, Stack, Typography } from '@mui/material'
import { useUpdateWidgetSettingsMutation } from 'api/projects'
import { dashboardWidgets, dashboardWidgetsDefaultData } from 'api/projects/types'
import Button from 'components/Button'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { DrawerTopBar } from 'pages/DocView/components/InternalAgreementDrawer'
import { DrawerWrapper } from 'pages/DocView/components/RemarkFormDrawer'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { onDrawerClose } from 'store/slices/documentsPages/drawerInfo'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'

import { RadioWrapper } from './WidgetsDrawer.styles'
import { IWidgetsDrawerFormData, IWidgetsDrawerProps, widgetDrawerRadioData } from './WidgetsDrawer.types'
import { WidgetsSection } from './components/WidgetsSection'

export const WidgetsDrawer: FC<IWidgetsDrawerProps> = ({ isOpen, widgetData }) => {
  const { allProjects = false, widgets = [] } = widgetData || {}
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const dispatch = useAppDispatch()
  const [updateWidgetSettings, { isLoading, ...response }] = useUpdateWidgetSettingsMutation()
  const { enqueueSnackbar } = useSnackbar()

  const initialWidgets = useMemo(() => {
    return widgets.length ? widgets : dashboardWidgets.map((widgetName) => ({ name: widgetName, enabled: true }))
  }, [widgets])

  const initialValues: IWidgetsDrawerFormData = useMemo(
    () => ({
      allProjects,
      widgets: initialWidgets,
    }),
    [widgetData],
  )

  const onSubmit = useCallback(
    (values: IWidgetsDrawerFormData) => {
      updateWidgetSettings({ projectId, body: values })
    },
    [projectId],
  )

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values),
  })

  const { values, dirty, setFieldValue, resetForm } = formik

  const isResetButtonDisabled: boolean = useMemo(() => {
    return JSON.stringify(dashboardWidgetsDefaultData) === JSON.stringify(values.widgets) && !values.allProjects
  }, [values])

  useEffect(() => {
    if (!isOpen) resetForm()
  }, [isOpen])

  const resetToDefault = () => {
    setFieldValue('widgets', dashboardWidgetsDefaultData)
    setFieldValue('allProjects', false)
  }

  const onClose = (immediately?: boolean) => {
    dispatch(onDrawerClose({ dirty, immediately }))
  }

  const onRadioChange = (value: boolean) => {
    setFieldValue('allProjects', value)
  }

  useMutationHandlers(response, () => {
    enqueueSnackbar('Изменения успешно сохранены.', { variant: 'success' })
    onClose(true)
  })

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onClose()}>
      <FormikProvider value={formik}>
        <Stack component={Form} flex={1} overflow={'auto'}>
          <DrawerWrapper>
            <Stack height={'100%'}>
              <DrawerTopBar>
                <Typography variant='h1' color={theme.palette.primary.main}>
                  Настройки отображения
                </Typography>
              </DrawerTopBar>
              <Divider />
              <Stack p={2.5} gap={2.5} overflow={'auto'} height={'100%'}>
                <Typography variant='body2' textAlign={'center'} color={theme.palette.text.dark}>
                  Установите удобный порядок отображения виджетов на экране проекта путем перемещения наименований
                  виджетов в списке. Чтобы скрыть неиспользуемые виджеты переведите переключатель в неактивное
                  положение. Настройки сохраняются на уровне пользователя и в зависимости от выбора будут применены
                  только к текущему проекту либо ко всем проектам.
                </Typography>

                <Button
                  onClick={resetToDefault}
                  disabled={isResetButtonDisabled}
                  variant='outlined'
                  color='secondary'
                  fullWidth
                  sx={{ border: 'none' }}
                >
                  Восстановить по умолчанию
                </Button>

                <Stack gap={1} overflow={'auto'}>
                  <Typography variant='body2' color={theme.palette.secondary.gray}>
                    Применение настроек
                  </Typography>
                  <Divider />

                  {widgetDrawerRadioData.map((item) => (
                    <RadioWrapper onClick={() => onRadioChange(item.value)}>
                      <Radio checked={item.value === values.allProjects} />
                      <Typography
                        fontSize={14}
                        variant='subtitle2'
                        textTransform={'uppercase'}
                        color={theme.palette.text.dark}
                      >
                        {item.label}
                      </Typography>
                    </RadioWrapper>
                  ))}

                  <Divider />

                  <WidgetsSection />
                </Stack>
              </Stack>

              <Stack p={2.5} direction={'row'} spacing={2}>
                <Button loading={isLoading} type='submit' disabled={!dirty} color='success' fullWidth>
                  Сохранить
                </Button>

                <Button fullWidth onClick={() => onClose()}>
                  Отменить
                </Button>
              </Stack>
            </Stack>
          </DrawerWrapper>
        </Stack>
      </FormikProvider>
    </Drawer>
  )
}
