import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Box, Divider, MenuItem, Stack, Tab, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetAmountRemarksQuery, useGetTomRemarksQuery } from '../../../../api/remarks'
import useQuery from '../../../../hooks/useQuery'
import { SideBarDocsWrapper } from '../../styles'
import { DocList } from '../DocList'
import { TabPanelProps } from '../DocViewNavBarContentAgreement/DocViewNavBarContentAgreement.types'
import {
  ScrollableContainer,
  SideBarWrapper,
  StyledBox,
  StyledButtonGroup,
  StyledSelect,
} from './DocViewRightSideBar.styles'
import { DocViewRightSideBarProps } from './DocViewRightSideBar.types'
import { FilterValues, remarkFilterValues, remarkFilterValuesRu, remarkFilters } from './DocViewRightSideBar.utils'
import { EmptyRemarks } from './components/EmptyRemarks/EmptyRemarks'
import { Remark } from './components/Remark'

function a11yProps(index: number) {
  return {
    id: `sidebar-tab-${index}`,
    'aria-controls': `sidebar-tabpanel-${index}`,
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Stack
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`sidebar-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Stack>
  )
}

export const DocViewRightSideBar = ({ hasNoDocument, tomVersions, tomId, type }: DocViewRightSideBarProps) => {
  const queryHandler = useQuery()
  const tabData = queryHandler.get('tab')
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const [tabUsed, setTabUsed] = useState<number>(tabData === 'docs' ? 0 : 2)
  const { data: remarks, isFetching: isRemarksFetching } = useGetTomRemarksQuery(
    { projectId, tomId: tomId!, type },
    { skip: !tomId },
  )
  const { data: remarksAmount } = useGetAmountRemarksQuery({ projectId, tomId: tomId!, type }, { skip: !tomId })
  const [filterValue, setFilterValue] = useState<FilterValues>('all')

  const remarksData = useMemo(() => {
    if (remarks?.data && filterValue === 'all') {
      return remarks.data
    }
    return remarks?.data.filter(({ remark }) => remark.status === filterValue) || []
  }, [filterValue, remarks?.data])

  useEffect(() => {
    if (hasNoDocument && tabData === 'docs' && !isRemarksFetching) {
      setTabUsed(2)
    }
  }, [hasNoDocument, tabData, isRemarksFetching])

  const onChangeTab = (newValue: any) => {
    if (hasNoDocument) return

    setTabUsed(newValue)
    queryHandler.set('tab', newValue === 0 ? 'docs' : 'remarks')
  }

  if (!remarks?.data.length && hasNoDocument) return null
  return (
    <SideBarWrapper $hasNoDocument={hasNoDocument}>
      <StyledButtonGroup variant='fullWidth' value={tabUsed} onChange={(e, newValue) => onChangeTab(newValue)}>
        {!hasNoDocument && <Tab disableRipple {...a11yProps(0)} label='Документы' />}
        {!hasNoDocument && <Divider orientation='vertical' variant='middle' flexItem></Divider>}
        <Tab
          disableRipple
          {...a11yProps(1)}
          sx={{ cursor: hasNoDocument ? 'default' : 'pointer' }}
          label={hasNoDocument ? 'Замечания к пред. версиям' : 'Замечания'}
        />
      </StyledButtonGroup>
      <ScrollableContainer>
        <CustomTabPanel value={tabUsed} index={0}>
          <SideBarDocsWrapper spacing={1.25} textAlign='left'>
            <DocList listVariant='changes' itemVariant='navbar' />
            {tomVersions[0] && <DocList listVariant='lastVersion' itemVariant='navbar' />}
          </SideBarDocsWrapper>
        </CustomTabPanel>
        <CustomTabPanel value={tabUsed} index={2}>
          {!remarks?.data.length ? (
            <EmptyRemarks />
          ) : (
            <Box>
              <StyledSelect
                disableUnderline
                displayEmpty
                variant='standard'
                fullWidth
                color='success'
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value as FilterValues)}
                renderValue={(value: any) => (
                  <Stack direction='row' alignItems='center' flex={1}>
                    <FilterAltIcon color='primary' fontSize='medium' />
                    <Stack flex={1}>
                      <Typography variant='h2' fontSize={14}>
                        {remarkFilterValuesRu[value as FilterValues]}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              >
                {remarkFilters.map((status) => (
                  <MenuItem
                    disabled={remarksAmount && remarksAmount[remarkFilterValues[status]] === 0}
                    key={status}
                    value={remarkFilterValues[status]}
                  >
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <StyledBox>
                        <Typography variant='h2' fontSize={16}>
                          {remarksAmount && remarksAmount[remarkFilterValues[status]]}
                        </Typography>
                      </StyledBox>
                      <Typography variant='h2' fontSize={16}>
                        {status}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </StyledSelect>
              {remarksData.map((remark) => (
                <Remark
                  canAddMessage={remark.canAddMessage}
                  remarkInfo={remark.remark}
                  messages={remark.messages}
                  key={remark.remark.id}
                />
              ))}
            </Box>
          )}
        </CustomTabPanel>
      </ScrollableContainer>
    </SideBarWrapper>
  )
}
