import React, { useState, MouseEvent } from "react";
import { StyledIconButton, StyledMenuItem } from "./RemarkMenu.styles"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Menu, Stack, Typography } from "@mui/material";
import { RemarkMenuProps } from "./RemarkMenu.types";

export const RemarkMenu = ({
  children,
  anchorOrigin,
  transformOrigin,
  data,
  title,
  anchorParentElement,
  position,
  menuWidth,
  disabled
}: RemarkMenuProps) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    
    setAnchorEl(anchorParentElement ? event.currentTarget.parentElement : event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const onSelectMenuItem = (action: () => void) => {
    action()
    setAnchorEl(null)
  }

  return (
    <>
      {children ?
        React.cloneElement(children, {
          'aria-label': 'more',
          id: 'long-button',
          'aria-controls': open ? 'menu' : undefined,
          'aria-expanded': open ? 'true' : undefined,
          'aria-haspopup': 'true',
          onClick: handleClick
        }) : (
          <StyledIconButton
            disabled={disabled}
            $position={position}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon fontSize="small" color={disabled ? 'disabled' : 'primary'} />
          </StyledIconButton>
        )}
      <Menu
        id="menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: anchorOrigin?.vertical || 'top',
          horizontal: anchorOrigin?.horizontal || 'center',
        }}
        transformOrigin={{
          vertical: transformOrigin?.vertical || 'top',
          horizontal: transformOrigin?.horizontal || 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: menuWidth || '298px',
            borderRadius: '16px'
          },
        }}
        sx={{ '.MuiList-root': { padding: 1 } }}
      >
        <Stack spacing={1}>
          <Stack direction='row' alignItems='center'>
            <Typography variant="body2" flex={1} textAlign='center'>
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" color="primary" />
            </IconButton>
          </Stack>
          {data && data.map((item) =>
            <StyledMenuItem onClick={() => onSelectMenuItem(item.action)}>
              <Typography textAlign='center' width='100%' color='inherit'>
                {item.itemName}
              </Typography>
            </StyledMenuItem>
          )}
        </Stack>
      </Menu>
    </>
  )
}