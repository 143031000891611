import { createTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
      xxl: 1800,
      xxxl: 2300,
    },
  },
  palette: {
    primary: {
      main: '#0044B4',
      light: '#6D9ADC',
      dark: '#00358C',
    },
    secondary: {
      dark: '#5C6E8C',
      gray: '#7890B2',
      main: '#5C6E8C',
    },
    error: {
      main: '#F46B6B',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
    },
    info: {
      main: '#0288D1',
      light: '#03A9F4',
    },
    success: {
      main: '#2E7D32',
      dark: '#19681D',
    },
    text: {
      dark: '#2B3648',
      light: '#ffffff',
    },

    legends: {
      blue: '#6D9ADC',
      border: '#0049FF',
      green: '#8DD4C8',
      red: '#FFB1B1',
      yellow: '#FFE08F',
      skyblue: '#68C9FF',
      lightblue: '#B3E5FC',
      darkblue: '#576F92',
      grayBlue: '#8495C0',
      orange: '#FE9B3F',
      lightOrange: '#FDBA74',
      lightYellow: '#FFCD4B26',
      purple: '#D0AFFF',
      darkPink: '#C17AA0',
      lightBlue: '#BFDBFE',
      lightgreen: '#6FCCBC',
      lightLime: '#D4E157',
      darkLime: '#C0CA33',
      olive: '#A5D6A7',
      text: '#668FD2',
      lightOlive: '#BCF5BF',
      gray: '#D2DCFF',
      violet: '#A394D4',
      chartsGreen: '#14B8A6',
      chartsBlue: '#93C5FD',
      chartsYellow: '#FDE047',
      successLight: '#8EDD92',
      disabled: '#9AA2B0',
      error: '#F46B6B',
      textBlue: '#0088D1',
    },
    bg: {
      gray: '#EDEFF3',
      white: '#ffffff',
      shades: '#F6F7FB',
      blue: '#1976D2',
      lightBlue: '#1976D214',
    },
    table: {
      yellow: '#FFCD4B',
      orange: '#ED6C02',
      green: '#8DD4C8',
      error: '#D32F2F',
      errorBg: '#ff0000',
    },
    purple: '#9427D7',
    disabled: '#9AA2B0',
    primaryBorderLight: '#8CBBE8',
  },
  components: {
    MuiSvgIcon: {
      variants: [
        {
          props: { color: 'secondaryDark' },
          style: ({ theme }) => ({
            color: theme.palette.secondary.dark,
          }),
        },
        {
          props: { color: 'dark' },
          style: ({ theme }) => ({
            color: theme.palette.text.dark,
          }),
        },
        {
          props: { color: 'purple' },
          style: ({ theme }) => ({
            color: theme.palette.legends.purple,
          }),
        },
        {
          props: { color: 'lightgreen' },
          style: ({ theme }) => ({
            color: theme.palette.legends.lightgreen,
          }),
        },
        {
          props: { color: 'disabled' },
          style: ({ theme }) => ({
            color: theme.palette.disabled,
          }),
        },
      ],
      defaultProps: {
        fontSize: 'large',
      },
      styleOverrides: {
        root: {
          fontSize: '20px !important',
        },
        fontSizeLarge: {
          fontSize: '24px !important',
        },
        fontSizeSmall: {
          fontSize: '16px !important',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'success' },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.table.green, 0.15),
          }),
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.text.light,
          }),
        },
        {
          props: { size: 'thin' },
          style: ({ theme }) => ({
            height: '30px',
            fontSize: '13px',
            lineHeight: '22px',
            letterSpacing: '0.46px',
          }),
        },
      ],
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          height: 40,
          padding: '8px 16px',
          whiteSpace: 'nowrap',
          borderRadius: 6,
          boxShadow: 'none',
        },
        text: {
          fontWeight: 500,
          textTransform: 'uppercase',
        },
        containedSecondary: ({ theme }) => ({
          color: theme.palette.primary.main,
          // backgroundColor: alpha(theme.palette.primary.light, 0.08) + 'rgba(25, 118, 210, 0.08) !important',
          backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
          // border: `1px solid ${theme.palette.primary.main}`
        }),
        textSecondary: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        outlinedSecondary: ({ theme }) => ({
          color: theme.palette.primary.main,
          backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
          border: `1px solid ${theme.palette.primary.main}`,
        }),
        sizeLarge: ({ theme }) => ({
          fontSize: '15px',
          lineHeight: '26px',
          letterSpacing: '0.46px',
        }),
        sizeMedium: ({ theme }) => ({
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
        }),
        sizeSmall: ({ theme }) => ({
          fontSize: '13px',
          lineHeight: '22px',
          letterSpacing: '0.46px',
        }),
        iconSizeLarge: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
        iconSizeMedium: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
        iconSizeSmall: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: ({ theme }) => ({
          color: theme.palette.secondary.main,
        }),
      },
    },
  },
})

theme.typography.h1 = {
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.text.dark}`,
}

theme.typography.h2 = {
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.primary.main}`,
}

theme.typography.h3 = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '140%',
  letterSpacing: '0.17px',
  textTransform: 'none',
  color: `${theme.palette.secondary.gray}`,
}

theme.typography.h4 = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  letterSpacing: '0.17px',
  textTransform: 'none',
  color: `${theme.palette.secondary.gray}`,
}

theme.typography.subtitle1 = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '175%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.primary.main}`,
}

theme.typography.subtitle2 = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '130%',
  letterSpacing: '0.1px',
  textTransform: 'none',
  color: `${theme.palette.primary.main}`,
}

theme.typography.body1 = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.text.dark}`,
}

theme.typography.body2 = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  letterSpacing: '0.17px',
  textTransform: 'none',
  color: `${theme.palette.secondary.dark}`,
}

theme.typography.buttonMedium = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '171%',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  color: `${theme.palette.secondary.dark}`,
}

theme.typography.buttonSmall = {
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '169%',
  letterSpacing: '0.46px',
  textTransform: 'uppercase',
  color: `${theme.palette.primary.main}`,
}

theme.typography.tooltip = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '110%',
  letterSpacing: 0,
  textTransform: 'uppercase',
  color: `${theme.palette.primary.main}`,
}
