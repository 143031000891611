import { RootState } from '../../../../store'
import { CurrentChangeSelector, CurrentDocumentSelector, DocsSelector, TomSelector } from './tom.selectors.types'

export const tomSelector = (state: RootState): TomSelector => ({
  tom: state.tom.tom,
})

export const roadmapAccess = (state: RootState): boolean => state.tom.roadmapAccess

export const docsSelector = (state: RootState): DocsSelector => ({
  tomVersions: state.tom.tomVersions,
  tomChanges: state.tom.tomChanges,
})

export const currentDocumentSelector = (state: RootState): CurrentDocumentSelector => ({
  currentDocument: state.tom.currentDocument,
})

export const currentChangeSelector = (state: RootState): CurrentChangeSelector => ({
  currentChange: state.tom.currentChange,
})

export const isCmnTomSelector = (state: RootState): boolean => state.tom.isCmnTom

export const documentPagesSelector = (state: RootState): number | null => state.tom.documentPages
