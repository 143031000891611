import { Stack } from '@mui/material'
import Button from 'components/Button'
import styled from 'styled-components'

interface ISettingsFormRowProps {
  _alignItems?: string
  _direction?: string
}

interface ISettingsFormItemProps extends ISettingsFormRowProps {}

export const SettingsFormHeader = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

export const SettingsFormRow = styled(Stack)<ISettingsFormRowProps>`
  flex-direction: ${(props) => props._direction || 'row'};
  align-items: ${(props) => props._alignItems || 'center'};
  gap: 40px;
`

export const SettingsFormItem = styled(Stack)<ISettingsFormItemProps>`
  flex-direction: ${(props) => props._direction || 'row'};
  align-items: ${(props) => props._alignItems || 'center'};
  width: 50%;
`

export const SmallButton = styled(Button)`
  min-width: 30px;
  width: 30px;
  height: 30px;
`
