import { Stack, Typography } from '@mui/material'
import { SettingsFormHeader, SettingsFormItem, SettingsFormRow, SmallButton } from './SettingsForm.styles'
import Button from 'components/Button'
import { theme } from 'styles/theme'
import { TanglLogoIcon } from 'assets/icons/TanglLogoIcon'
import FieldForm from 'components/FieldForm'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { validationTanglIntegrating } from './validation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ITanglIntegrationFormData } from './SettingsForm.types'
import { FieldFormLabel } from 'components/FieldFormLabel'
import { IntegrationStatus } from './components/IntegrationStatus'
import { useAppDispatch } from 'store/store'
import {
  useGetTanglIntegrationSettignsQuery,
  useGetTanglIntegrationStatusQuery,
  useSyncTanglIntegrationMutation,
  useUpdateTanglIntegrationSettinsMutation,
  useUpdateTanglIntegrationStatusMutation,
} from 'api/settings'
import {
  IIntegrateTanglRequestAccount,
  tanglIntegrationErrorTextByStatus,
  TTanglIntegrationErrorStatus,
  TTanglIntegrationStatus,
} from 'api/settings/types'
import Progress from 'components/Progress'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { setOpenedConfirm } from 'store/slices/documentsPages/drawerInfo'
import Tooltip from 'components/Tooltip'
import { Repeat as RepeatIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

export const SettingsForm = () => {
  const dispatch = useAppDispatch()

  const { data: tanglIntegrationSettigns, isLoading: isTanglIntegrationSettignsLoading } =
    useGetTanglIntegrationSettignsQuery()
  const { data: tanglIntegrationStatus, isLoading: isTanglIntegrationStatusLoading } =
    useGetTanglIntegrationStatusQuery()
  const status: TTanglIntegrationStatus = tanglIntegrationStatus?.status || 'DISABLED'
  const isStatusError: boolean = status == 'ERROR_AUTH' || status == 'ERROR_COMPANY' || status == 'ERROR_ACCESS'
  const { enqueueSnackbar } = useSnackbar()

  const [updateTanglIntegrationSettins, { isLoading: isSettingsUpdating, ...updateTanglIntegrationSettiпgsResponse }] =
    useUpdateTanglIntegrationSettinsMutation()

  const [updateTanglIntegrationStatus, { isLoading: isStatusUpdating, ...updateTanglIntegrationStatusResponse }] =
    useUpdateTanglIntegrationStatusMutation()

  const [syncTanglIntegration, { isLoading: isSyncing, ...syncTanglIntegrationResponse }] =
    useSyncTanglIntegrationMutation()

  const isQuerying = isSettingsUpdating || isStatusUpdating
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [isFormClearing, setIsFormClearing] = useState<boolean>(false)

  const initialValues: ITanglIntegrationFormData = useMemo(
    () => ({
      key: '',
      secret: '',
      login: '',
      password: '',
      companyId: '',
    }),
    [],
  )

  const onSubmit = useCallback((values: ITanglIntegrationFormData) => {
    const { key, secret, login, password, companyId } = values

    const dataForRequest: IIntegrateTanglRequestAccount = {
      clientId: key,
      clientSecret: secret,
      username: login,
      password,
      companyId,
    }

    updateTanglIntegrationSettins({ body: { account: dataForRequest } })
  }, [])

  const { formik } = useForm({
    validationSchema: validationTanglIntegrating,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values),
  })

  const { values, dirty, isValid, setValues, resetForm } = formik

  const dataWithSavedSettings = useMemo(() => {
    const { clientId, clientSecret, username, password, companyId } = tanglIntegrationSettigns?.account || {}

    const data: ITanglIntegrationFormData = {
      key: clientId || '',
      secret: clientSecret || '',
      login: username || '',
      password: password || '',
      companyId: companyId || '',
    }

    return data
  }, [tanglIntegrationSettigns])

  useEffect(() => {
    const isDirty: boolean = !isEqual(values, dataWithSavedSettings)

    dispatch(setOpenedConfirm({ openedConfirm: isDirty }))
  }, [values, dataWithSavedSettings])

  useEffect(() => {
    setValues(dataWithSavedSettings)
  }, [dataWithSavedSettings])

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const onDisableIntegrationClick = () => {
    updateTanglIntegrationStatus({ status: 'DISABLED' })
  }

  const onSyncClick = () => {
    syncTanglIntegration()
  }

  const onClearFormClick = () => {
    setIsFormClearing(true)
    setValues({
      key: '',
      secret: '',
      login: '',
      password: '',
      companyId: '',
    })
    updateTanglIntegrationSettins({
      body: { account: { clientId: '', clientSecret: '', username: '', password: '', companyId: '' } },
    })
  }

  useMutationHandlers(updateTanglIntegrationSettiпgsResponse, (data) => {
    updateTanglIntegrationStatus({ status: isFormClearing ? 'DISABLED' : 'ENABLED' })
    isFormClearing && resetForm()
  })

  useMutationHandlers(updateTanglIntegrationStatusResponse, (data) => {
    setIsFormLoading(false)
    setIsFormClearing(false)
  })

  useMutationHandlers(syncTanglIntegrationResponse, () => {
    enqueueSnackbar('Синхронизация с Tangl успешно запущена.', { variant: 'success' })
  })

  return (
    <FormikProvider value={formik}>
      <Stack width={'50%'} component={Form}>
        {(isTanglIntegrationSettignsLoading || isTanglIntegrationStatusLoading) && <Progress />}

        {!isTanglIntegrationSettignsLoading && !isTanglIntegrationStatusLoading && (
          <>
            <SettingsFormHeader>
              <Stack padding={0.5} direction={'row'} spacing={1} alignItems={'center'}>
                <TanglLogoIcon width={'32px'} />
                <Typography variant='body2' fontSize={20}>
                  Tangl
                </Typography>
              </Stack>
              {status !== 'ENABLED' && (
                <Button
                  loading={isFormClearing}
                  onClick={onClearFormClick}
                  disabled={!dirty || isFormLoading}
                  variant='outlined'
                  color='secondary'
                >
                  <Typography variant='body2' color={theme.palette.primary[dirty ? 'main' : 'light']}>
                    Очистить данные
                  </Typography>
                </Button>
              )}
            </SettingsFormHeader>
            <Stack spacing={5} padding={'20px 15px 20px 30px'}>
              <SettingsFormRow>
                <SettingsFormItem _alignItems={'flex-start'} height={'100%'}>
                  <Typography textAlign={'start'} color={theme.palette.text.dark} variant='subtitle2'>
                    Статус подключения
                  </Typography>
                </SettingsFormItem>

                <Stack width={'50%'}>
                  <Stack flexWrap={'wrap'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <IntegrationStatus status={status} />
                    {status === 'ENABLED' && (
                      <Tooltip title='Синхронизировать'>
                        <div>
                          <SmallButton onClick={onSyncClick} icon>
                            {isSyncing ? (
                              <Progress size={16} sx={{ color: theme.palette.text.light }} />
                            ) : (
                              <RepeatIcon fontSize='small' />
                            )}
                          </SmallButton>
                        </div>
                      </Tooltip>
                    )}
                    {isStatusError && (
                      <Button disabled={!isValid || isFormLoading || isFormClearing} type='submit' variant='text'>
                        Попробовать еще раз
                      </Button>
                    )}
                  </Stack>
                  {isStatusError && (
                    <Typography textAlign={'start'} variant='body2'>
                      {tanglIntegrationErrorTextByStatus[status as TTanglIntegrationErrorStatus]}
                    </Typography>
                  )}
                </Stack>
              </SettingsFormRow>

              <SettingsFormRow _alignItems={'flex-start'}>
                <SettingsFormItem _direction={'column'} spacing={1} _alignItems='flex-start'>
                  <Typography textAlign={'start'} color={theme.palette.text.dark} variant='subtitle2'>
                    Ключ API и Secret для подключения к Tangl
                  </Typography>
                  <Typography textAlign={'start'} variant='body2'>
                    Создание идентификатов API доступно в настройках аккаунта Tangl.
                  </Typography>
                </SettingsFormItem>

                <SettingsFormItem _direction='column' spacing={1.5}>
                  <FieldFormLabel label='Ключ API' isRequired>
                    <FieldForm
                      disabled={status === 'ENABLED'}
                      name='key'
                      version='doc'
                      placeholder='Введите ключ API'
                      helperText=''
                    />
                  </FieldFormLabel>

                  <FieldFormLabel label='Secret' isRequired>
                    <FieldForm
                      disabled={status === 'ENABLED'}
                      name='secret'
                      version='password'
                      placeholder='Введите Secret'
                      helperText=''
                    />
                  </FieldFormLabel>
                </SettingsFormItem>
              </SettingsFormRow>

              <SettingsFormRow _alignItems={'flex-start'}>
                <SettingsFormItem _direction={'column'} spacing={1} _alignItems='flex-start'>
                  <Typography textAlign={'start'} color={theme.palette.text.dark} variant='subtitle2'>
                    Данные аккаунта Tangl
                  </Typography>
                  <Typography textAlign={'start'} variant='body2'>
                    Для удаленной авторизации укажите логин и пароль от учётной записи вашей компании в Tangl.
                  </Typography>
                </SettingsFormItem>

                <SettingsFormItem _direction='column' spacing={1.5}>
                  <FieldFormLabel label='Логин' isRequired>
                    <FieldForm
                      disabled={status === 'ENABLED'}
                      name='login'
                      version='doc'
                      placeholder='Введите логин'
                      helperText=''
                    />
                  </FieldFormLabel>

                  <FieldFormLabel label='Пароль' isRequired>
                    <FieldForm
                      disabled={status === 'ENABLED'}
                      name='password'
                      version='password'
                      placeholder='Введите пароль'
                      helperText=''
                    />
                  </FieldFormLabel>
                </SettingsFormItem>
              </SettingsFormRow>

              <SettingsFormRow _alignItems={'flex-start'}>
                <SettingsFormItem _direction={'column'} spacing={1} _alignItems='flex-start'>
                  <Typography textAlign={'start'} color={theme.palette.text.dark} variant='subtitle2'>
                    ID компании в Tangl
                  </Typography>
                  <Typography textAlign={'start'} variant='body2'>
                    Для получения ID корп. компании обратитесь в поддержку Tangl.
                  </Typography>
                </SettingsFormItem>

                <SettingsFormItem _direction='column' spacing={1.5}>
                  <FieldFormLabel label='ID компании' isRequired>
                    <FieldForm
                      disabled={status === 'ENABLED'}
                      name='companyId'
                      version='doc'
                      placeholder='Введите ID компании'
                      helperText=''
                    />
                  </FieldFormLabel>
                </SettingsFormItem>
              </SettingsFormRow>

              <SettingsFormRow>
                <SettingsFormItem></SettingsFormItem>
                <SettingsFormItem>
                  {status == 'ENABLED' ? (
                    <Button onClick={onDisableIntegrationClick} color='error' loading={isFormLoading}>
                      Отключить
                    </Button>
                  ) : (
                    <Button type='submit' loading={isFormLoading && !isFormClearing} disabled={!dirty || !isValid}>
                      Подключить
                    </Button>
                  )}
                </SettingsFormItem>
              </SettingsFormRow>
            </Stack>
          </>
        )}
      </Stack>
    </FormikProvider>
  )
}
