import { FormControl, MenuItem, Stack, Typography } from "@mui/material"
import { ZoomActionButton, StyledSelect } from "./ZoomComponent.styles"
import { AlignmentType, ZoomComponentProps } from "./ZoomComponent.types"
import { KeyboardArrowDown as ArrowIcon } from '@mui/icons-material';
import { useCallback, useEffect } from "react";

export const ZoomComponent = ({
  zoom,
  step = 10,
  minZoom = 10,
  maxZoom = 400,
  rootContainerRef,
  onChangeZoom,
  onChangeAlignment
}: ZoomComponentProps) => {

  const handleChangeZoom = (value: number | AlignmentType) => {
    if (typeof value === 'number') {
      onChangeZoom(value)
    } else {
      onChangeAlignment(value)
    }
  }

  const increaseZoom = () => {
    onChangeZoom(Math.min(zoom + step, maxZoom))
  }

  const decreaseZoom = () => {
    onChangeZoom(Math.max(zoom - step, minZoom))
  }

  const getSelectContainer = () => {
    return document.fullscreenElement || document.body
  }

  const handleWheelZoom = useCallback(
    (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault()
        if (event.deltaY < 0) {
          onChangeZoom(Math.min(zoom + step, maxZoom))
        } else {
          onChangeZoom(Math.max(zoom - step, minZoom))
        }
      }
    },
    [onChangeZoom, zoom]
  )

  useEffect(() => {
    const container = rootContainerRef.current
    
    if (container) {
      container.addEventListener('wheel', handleWheelZoom, { passive: false })
      
      return () => {
        container.removeEventListener('wheel', handleWheelZoom)
      };
    }
  }, [handleWheelZoom])

  return (
    <Stack direction='row' spacing={1}>
      <ZoomActionButton disableRipple onClick={decreaseZoom}>-</ZoomActionButton>
      <FormControl sx={{ minWidth: 87 }} size="small">
        <StyledSelect
          value={zoom}
          renderValue={(value) => <Typography fontSize={14}>{value as number}%</Typography>}
          onChange={(e) => handleChangeZoom(e.target.value as number | AlignmentType)}
          IconComponent={ArrowIcon}
          MenuProps={{
            container: getSelectContainer(),
            PaperProps: {
              style: {
                maxHeight: 300,
                zIndex: 1302
              },
            },
          }}
        >
          <MenuItem value='height'>По размеру страницы</MenuItem>
          <MenuItem value='width'>По ширине страницы</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
          <MenuItem value={150}>150%</MenuItem>
          <MenuItem value={200}>200%</MenuItem>
          <MenuItem value={250}>250%</MenuItem>
          <MenuItem value={300}>300%</MenuItem>
          <MenuItem value={350}>350%</MenuItem>
          <MenuItem value={400}>400%</MenuItem>
        </StyledSelect>
      </FormControl>
      <ZoomActionButton disableRipple onClick={increaseZoom}>+</ZoomActionButton>
    </Stack>
  )
}