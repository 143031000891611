import { useParams } from "react-router-dom"
import { InternalAgreementWrapper } from "./InternalAgreementSchemes.styles"
import { InternalAgreementControl } from "./components/InternalAgreementControl"
import { InternalAgreementTitle } from "./components/InternalAgreementTitle"
import { useAppDispatch, useTypedSelector } from "../../../store/store"
import { TableFormValues, currentSchemaSelector, isOpenFormAddMemberSelector, isSelectedProcedureSelector, setCurrentSchema, setIsOpenFormAddMember, setTableFormValues } from "../../../store/slices/internalAgreement"
import { InternalAgreementForm, InternalAgreementFormValues } from "./components/InternalAgreementForm"
import { useAddInnermapMemberMutation, useEditInnermapMemberMutation } from "../../../api/internalAgreement"
import { useEffect } from "react"
import { InternalAgreementTable } from "./components/InternalAgreementTable/InternalAgreementTable"
import { EditMemberFormValues } from "../../../api/internalAgreement/types"
import { InternalAgreementAdvice } from "./components/InternalAgreementAdvice"
import { useSnackbar } from "notistack"

export const InternalAgreementSchemes = () => {

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const [addInnermapMember, addInnermapMemberResponse] = useAddInnermapMemberMutation()
  const [editInnermapMember, editInnermapMemberResponse] = useEditInnermapMemberMutation()

  const currentSchema = useTypedSelector(currentSchemaSelector)
  const isOpenForm = useTypedSelector(isOpenFormAddMemberSelector)
  const isSelectedProcedure = useTypedSelector(isSelectedProcedureSelector)

  const closeForm = () => {
    dispatch(setIsOpenFormAddMember({ isOpenFormAddMember: { isOpen: false, formMode: 'create' } }))
    dispatch(setTableFormValues({ tableFormValues: {} as TableFormValues }))
  }
  const addMember = (values: InternalAgreementFormValues) => {
    addInnermapMember({ projectId, innermapId: currentSchema?.id, ...values })
  }

  const editMember = (values: EditMemberFormValues) => {
    editInnermapMember({ projectId, innermapId: currentSchema?.id, ...values })
  }

  useEffect(() => {
    if (addInnermapMemberResponse.data) {
      closeForm()
      dispatch(setCurrentSchema({ ...currentSchema, members: currentSchema.members + 1 }))
    }
    if (addInnermapMemberResponse.isError) {
      enqueueSnackbar('Не удалось добавить пользователя, попробуйте еще раз.', { variant: 'error' })
    }
  }, [addInnermapMemberResponse])

  useEffect(() => {
    if (editInnermapMemberResponse.data) {
      closeForm()
    }
    if (editInnermapMemberResponse.isError) {
      enqueueSnackbar('Не удалось редактировать пользователя, попробуйте еще раз.', { variant: 'error' })

    }
  }, [editInnermapMemberResponse])

  return (

    <InternalAgreementWrapper>
      <InternalAgreementControl />
      {(!currentSchema?.members && !isOpenForm.isOpen) &&
        <InternalAgreementTitle />}
      {(currentSchema?.isBroken && currentSchema?.members > 0) &&
        <InternalAgreementAdvice />}
      {(isOpenForm.isOpen && currentSchema.id) &&
        <InternalAgreementForm
          closeForm={closeForm}
          addMember={addMember}
          editMember={editMember}
          variant='page'
          formMode={isOpenForm.formMode}
          innermapId={currentSchema?.id} />}
      {currentSchema?.members > 0 &&
        <InternalAgreementTable
          projectId={projectId}
          innermapId={currentSchema?.id}
          isSelectedProcedure={isSelectedProcedure} />
      }
    </InternalAgreementWrapper>
  )
}