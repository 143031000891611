import { RemarkInfo, RemarkMessages, RemarkStatus } from "../../../../../../api/remarks/types"

export type RemarkColorLabeL = 'gray' | 'red' | 'green' | 'blue' | 'yellow' | 'lightgreen'

export type RemarkStatusRu = 'Создано' | 'Направлено' | 'В работе' | 'Отклонено' | 'Устранено' | 'Закрыто' | 'Снято'

export const remarkStatusRuArray: RemarkStatusRu[] = ['Создано', 'Направлено', 'В работе', 'Отклонено', 'Устранено', 'Закрыто', 'Снято']

export type RemarkMenuTitle = 'Удалить замечание' | 'Редактировать замечание'

export interface RemarkProps {
  canAddMessage: boolean
  remarkInfo: RemarkInfo
  messages: RemarkMessages[]
}
export interface RemarkStatusEnToRu extends Record<RemarkStatus, RemarkStatusRu> { }
export const remarkStatusEnToRu: RemarkStatusEnToRu = {
  'submitted': 'Направлено',
  'closed': 'Закрыто',
  'accepted': 'В работе',
  'rejected': 'Отклонено',
  'cancelled': 'Снято',
  'created': 'Создано',
  'resolved': 'Устранено'
}

export interface RemarkStatusRuToEn extends Record<RemarkStatusRu, RemarkStatus> { }
export const remarkStatusRuToEn: RemarkStatusRuToEn = {
  'Направлено': 'submitted',
  'Закрыто': 'closed',
  'В работе': 'accepted',
  'Отклонено': 'rejected',
  'Снято': 'cancelled',
  'Создано': 'created',
  'Устранено': 'resolved'
}

export interface RemarkColorStatus extends Record<RemarkStatus, RemarkColorLabeL> {}
export const remarkColorStatus: RemarkColorStatus = {
  'submitted': 'blue',
  'closed': 'lightgreen',
  'accepted': 'green',
  'rejected': 'red',
  'cancelled': 'gray',
  'created': 'green',
  'resolved': 'yellow'
}
