import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { CurrentDocStorageData } from '../../../../layouts/DocumentsLayout'
import { ProjectPhase } from '../../../../types/project'
import { TomDocument } from '../../../../types/tom'
import { AppDispatch, RootState } from '../../../store'
import {
  SetCurrentChangePayload,
  SetCurrentDocumentPayload,
  SetDefaultCurrentDocument,
  SetDocsPayload,
  SetDocumentPages,
  SetIsCmnTom,
  SetRoadmapAccessPayload,
  SetTomPayload,
} from './tom.payloads.types'
import { TomState } from './tom.types'

let initialState: TomState = {
  tom: null,
  roadmapAccess: false,
  tomVersions: [],
  tomChanges: [],
  currentDocument: {} as TomDocument,
  currentChange: null,
  isCmnTom: false,
  documentPages: null,
}

const tomSlice = createSlice({
  name: 'tomState',
  initialState,
  reducers: {
    setTom: (state, { payload }: PayloadAction<SetTomPayload>) => {
      const { tom } = payload

      state.tom = tom
    },
    setRoadmapAccess: (state, { payload }: PayloadAction<SetRoadmapAccessPayload>) => {
      state.roadmapAccess = payload.roadmapAccess
    },
    setDocs: (state, { payload }: PayloadAction<SetDocsPayload>) => {
      const { tomVersions, tomChanges } = payload

      state.tomVersions = tomVersions
      state.tomChanges = tomChanges
    },
    setCurrentDocument: (state, { payload }: PayloadAction<SetCurrentDocumentPayload>) => {
      const { currentDocument } = payload
      const currentSelectedPhase = localStorage.getItem('selectedProjectPhase') as ProjectPhase
      const dataForCurrentDocStorage: CurrentDocStorageData = {
        projectPhase: currentSelectedPhase,
        variant: currentDocument.version ? 'version' : 'change',
        docId: currentDocument.docId,
      }

      state.currentDocument = currentDocument
      localStorage.setItem('currentDocStorageData', JSON.stringify(dataForCurrentDocStorage))
    },
    clearCurrentDocument: (state) => {
      state.currentDocument = {} as TomDocument
    },
    setCurrentChange: (state, { payload }: PayloadAction<SetCurrentChangePayload>) => {
      const { change } = payload
      change
        ? (state.currentChange = state.tomChanges.find((item) => item.doc.change === change) || null)
        : (state.currentChange = null)
    },
    setIsCmnTom: (state, { payload }: PayloadAction<SetIsCmnTom>) => {
      const { isCmnTom } = payload
      state.isCmnTom = isCmnTom
    },
    setDocumentPages: (state, { payload }: PayloadAction<SetDocumentPages>) => {
      state.documentPages = payload.documentPages
    },
  },
})

export const setDefaultCurrentDocument = createAsyncThunk<
  void,
  SetDefaultCurrentDocument,
  { dispatch: AppDispatch; state: RootState }
>('defaultCurrentDocument/set', async ({ tomVersions, tomChanges }, { getState, dispatch }) => {
  const state = getState()
  const {
    projectInfo: { selectedProjectPhase },
  } = state

  const defaultDocForSet = tomVersions?.length ? tomVersions[0] : tomChanges?.length ? tomChanges[0].doc : null

  const currentDocStorageData: CurrentDocStorageData = JSON.parse(localStorage.getItem('currentDocStorageData') || '{}')

  if (selectedProjectPhase !== currentDocStorageData.projectPhase || !currentDocStorageData.docId) {
    dispatch(setCurrentDocument({ currentDocument: defaultDocForSet || ({} as TomDocument) }))
    return
  }
  /* defaultDocForSet
        ? dispatch(setCurrentDocument({ currentDocument: defaultDocForSet }))
        : dispatch(setCurrentDocument({ currentDocument: {} as TomDocument })) */

  let foundDoc: TomDocument | undefined

  switch (currentDocStorageData.variant) {
    case 'version':
      foundDoc = tomVersions?.find((version) => version.docId === currentDocStorageData.docId)
      break
    case 'change':
      foundDoc = tomChanges?.find((change) => change.doc.docId === currentDocStorageData.docId)?.doc
      break
  }

  foundDoc
    ? dispatch(setCurrentDocument({ currentDocument: foundDoc }))
    : dispatch(setCurrentDocument({ currentDocument: defaultDocForSet || ({} as TomDocument) }))
})

export const { reducer: tomReducer } = tomSlice
export const {
  setTom,
  setRoadmapAccess,
  setDocs,
  setCurrentDocument,
  setCurrentChange,
  setIsCmnTom,
  setDocumentPages,
  clearCurrentDocument,
} = tomSlice.actions
