import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RemarksState } from './remarks.types'

let initialState: RemarksState = {
  remarkFormData: {
    page: null,
    pages: null,
    version: null,
    change: null,
    type: '',
    comment: '',
    reason: '',
    fileList: null,
    remarkId: null,
    tomId: undefined,
  },
  remarkFormMode: 'create',
  remarkPhaseFilter: undefined,
  allowScrolling: {
    allowScrolling: false,
    mode: 'create',
    id: null,
  },
  remarkGroupFilter: {
    second: undefined,
    group: null,
    obj: null,
    authors: null,
    companies: null,
  },
  remarkHistoryData: {
    remarkId: null,
    index: null,
  },
}

const remarksSlice = createSlice({
  name: 'remarks',
  initialState,
  reducers: {
    setRemarkFormData: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkFormData'>>) => {
      state.remarkFormData = payload.remarkFormData
    },
    setRemarkFormMode: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkFormMode'>>) => {
      state.remarkFormMode = payload.remarkFormMode
    },
    setRemarkPhaseFilter: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkPhaseFilter'>>) => {
      state.remarkPhaseFilter = payload.remarkPhaseFilter
    },
    setRemarkGroupFilter: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkGroupFilter'>>) => {
      state.remarkGroupFilter = payload.remarkGroupFilter
    },
    setAllowScrolling: (state, { payload }: PayloadAction<Pick<RemarksState, 'allowScrolling'>>) => {
      state.allowScrolling = payload.allowScrolling
    },
    setRemarkHistoryData: (state, { payload }: PayloadAction<Pick<RemarksState, 'remarkHistoryData'>>) => {
      state.remarkHistoryData = payload.remarkHistoryData
    },
  },
})

export const { reducer: remarksReducer } = remarksSlice
export const {
  setRemarkFormData,
  setRemarkFormMode,
  setRemarkPhaseFilter,
  setRemarkGroupFilter,
  setAllowScrolling,
  setRemarkHistoryData,
} = remarksSlice.actions
