import { ToolButton } from "../../PragmaPdfViewer.styles"
import DownloadIcon from '@mui/icons-material/Download'
import { DownloadDocumentComponentProps } from "./DownloadDocumentComponent.types"
import { pdfjs } from "react-pdf"
import { useState } from "react"
import { CircularProgress } from "@mui/material"
import { Tooltip } from "../Tooltip"

const getFileName = (url: string) => url.split('/').pop()?.split('#')[0]?.split('?')[0] || "document.pdf"

export const DownloadDocumentComponent = ({ file }: DownloadDocumentComponentProps) => {

  const [isLoading, setIsLoading] = useState(false)

  const downloadPDF = async () => {
    setIsLoading(true)
    if (file) {
      try {
        const loadingTask = pdfjs.getDocument(file)
        const pdfDocument = await loadingTask.promise
        const pdfData = await pdfDocument.getData()

        const blob = new Blob([pdfData], { type: "application/pdf" })
        const url = URL.createObjectURL(blob)

        const a = document.createElement("a")
        a.href = url;
        a.download = getFileName(file)
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      } catch (error) {
        console.log('Не удалось загрузить файл')
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Tooltip title='Скачать' >
      <ToolButton onClick={downloadPDF} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
      </ToolButton>
    </Tooltip>
  )
}