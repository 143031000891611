import { pdfjs, Document, Page, } from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './LibraryStyles.css';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Wrapper, ToolBar, ToolButton, ActionButton, PageContainer, ScrollContainer } from "./PragmaPdfViewer.styles";
import { DocumentInitParameters } from "pdfjs-dist/types/src/display/api";
import { AlignmentType, ZoomComponent } from "./components/ZoomComponent";
import { PageNumberComponent } from "./components/PageNumberComponent";
import { Stack, Typography } from "@mui/material";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import PrintIcon from '@mui/icons-material/Print';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { DownloadDocumentComponent } from "./components/DownloadDocumentComponent";
import printJS from "print-js";
import { Loader } from "./components/Loader/Loader";
import { Tooltip } from "./components/Tooltip";
import { LazyPageProps, PageSize, PragmaPdfViewerProps } from "./PragmaPdfViewer.types";
import { Placeholder } from "./components/Placeholder";
import { HandToolComponent } from "./components/HandToolComponent";

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

const initialZoom = 100

const options: Omit<DocumentInitParameters, "data" | "range" | "url"> = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  rangeChunkSize: 200536,
  disableAutoFetch: true,
  disableStream: true
}

export const PragmaPdfViewer = memo(({ file, fileName, actionButtonRemark }: PragmaPdfViewerProps) => {
  const memoizedFile = useMemo(() => file, [file])
  const [zoom, setZoom] = useState<number>(initialZoom)
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [pageSize, setPageSize] = useState<PageSize | null>(null)
  const [widthAlignment, setWidthAlignment] = useState<boolean>(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [handTool, setHandTool] = useState(false)
  const [defaultHeight, setDefaultHeight] = useState(window.innerHeight - 174)
  const rootContainerRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const scale = zoom / 100

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const handlePageSize = (height: number, width: number) => {
    if (!pageSize) setPageSize({ height, width })
  }

  const scrollToPage = useCallback((page: number) => {
    if (!scrollContainerRef.current) return
    const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${page - 1}']`)

    if (pageElement) {
      setIsScrolling(true)
      const { offsetTop } = pageElement
      scrollContainerRef.current.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })

      setCurrentPage(page)
      setTimeout(() => {
        setIsScrolling(false)
      }, 1000)
    }
  }, [])

  const changeZoom = useCallback((value: number) => {
    setZoom(value)
  }, [])

  const changeAlignMent = useCallback((value: AlignmentType) => {
    setWidthAlignment(value !== 'height')
    setPageSize(null)
    setZoom(initialZoom)
  }, [])

  const changeRotate = () => {
    const rotatedValue = 90
    let rotateCurrent = rotate
    if (rotate === 0) rotateCurrent = 360
    setPageSize(null)
    setRotate(rotateCurrent - rotatedValue)
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      rootContainerRef.current?.requestFullscreen().catch(err => console.error('Полноэкранный режим не поддерживается'))
    } else {
      document.exitFullscreen()
    }
  }

  const onActionButtonRemark = () => {
    actionButtonRemark!(currentPage, numPages)
  }

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener('fullscreenchange', onFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
    }
  }, [])

  useEffect(() => {
    if (document.fullscreenElement && isFullScreen) {
      setDefaultHeight(document.fullscreenElement.clientHeight - 86)
    } else {
      setDefaultHeight(window.innerHeight - 174)
    }
    setPageSize(null)
    setZoom(initialZoom)
  }, [isFullScreen])

  const handleScrollEnd = useCallback(() => {
    if (!scrollContainerRef.current || isScrolling) return

    const { scrollTop, clientHeight } = scrollContainerRef.current
    const scrollMiddle = scrollTop + clientHeight / 2

    let newCurrentPage = currentPage

    for (let pageIndex = 0; pageIndex < numPages; pageIndex++) {
      const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${pageIndex}']`)

      if (pageElement) {
        const { offsetTop, clientHeight } = pageElement
        const pageMiddle = offsetTop + clientHeight / 2

        if (Math.abs(pageMiddle - scrollMiddle) < clientHeight / 2) {
          newCurrentPage = pageIndex + 1
          break
        }
      }
    }

    if (newCurrentPage !== currentPage) {
      setCurrentPage(newCurrentPage)
    }
  }, [numPages, currentPage, isScrolling])


  const handleScroll = useCallback(() => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current)
    }

    scrollTimeoutRef.current = setTimeout(handleScrollEnd, 100)
  }, [handleScrollEnd])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    scrollContainer.addEventListener('scroll', handleScroll)
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll)
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [handleScroll])

  /*   const loadAdjacentPages = useCallback(() => {
      setTimeout(() => {
        setLoadedPages((prevLoaded) => {
          const nextPages = []
          for (let i = Math.max(0, currentPage - 2); i <= Math.min(numPages - 1, currentPage + 2); i++) {
            if (!prevLoaded.includes(i)) {
              nextPages.push(i)
            }
          }
          return [...prevLoaded, ...nextPages]
        })
      }, 500); 
    }, [currentPage, numPages])
  
    useEffect(() => {
      loadAdjacentPages()
    }, [currentPage, loadAdjacentPages]); */

  return (
    <Wrapper spacing={1} ref={rootContainerRef}>
      <ToolBar>
        <Stack direction='row' alignItems='center' spacing={2}>
          {actionButtonRemark &&
            <Tooltip title='Замечание'>
              <ActionButton disableRipple onClick={onActionButtonRemark} disabled={isFullScreen}>
                <AddCommentIcon fontSize="medium" />
              </ActionButton>
            </Tooltip>}
          <ZoomComponent
            zoom={zoom}
            rootContainerRef={rootContainerRef}
            onChangeZoom={changeZoom}
            onChangeAlignment={changeAlignMent} />
          {/*           <HandToolComponent
            scrollContainerRef={scrollContainerRef}
            handTool={handTool}
            setHandTool={(value) => setHandTool(value)}
          /> */}
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          {fileName &&
            <Typography fontWeight={600} fontSize={16}>{fileName}</Typography>}
          <PageNumberComponent
            currentPage={currentPage}
            numPages={numPages}
            onPageChange={scrollToPage} />
        </Stack>
        <Stack direction='row' spacing={1.25}>
          <Tooltip title='На весь экран'>
            <ToolButton onClick={toggleFullScreen}>
              <AspectRatioIcon />
            </ToolButton>
          </Tooltip>
          <Tooltip title='Повернуть против часовой стрелки'>
            <ToolButton onClick={changeRotate}>
              <Rotate90DegreesCcwIcon />
            </ToolButton>
          </Tooltip>
          <DownloadDocumentComponent file={memoizedFile} />
          <Tooltip title='Печать'>
            <ToolButton disabled={isFullScreen} onClick={() => printJS({
              printable: memoizedFile,
              type: 'pdf',
              showModal: true,
              modalMessage: 'Получение документа...'
            })}>
              <PrintIcon />
            </ToolButton>
          </Tooltip>
        </Stack>
      </ToolBar>

      <Document
        file={memoizedFile}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        rotate={rotate}
        loading={<Loader height={defaultHeight - 18} />}
        externalLinkTarget="_blank"
      >
        <ScrollContainer ref={scrollContainerRef}>
          {Array.from({ length: numPages }, (_, index) => (
            <LazyPage
              key={`page_${index}`}
              pageIndex={index}
              scale={scale}
              currentPage={currentPage}
              defaultHeight={defaultHeight}
              widthAlignment={widthAlignment}
              scrollContainerRef={scrollContainerRef}
              pageSize={pageSize}
              rotate={rotate}
              handTool={handTool}
              onSizeUpdate={handlePageSize}
            />
          ))}
        </ScrollContainer>
      </Document>
    </Wrapper>
  )
})

const LazyPage = memo(({
  pageIndex,
  scale,
  currentPage,
  defaultHeight,
  widthAlignment,
  scrollContainerRef,
  pageSize,
  rotate,
  handTool,
  onSizeUpdate
}: LazyPageProps) => {

  if (pageIndex === currentPage - 3 || pageIndex === currentPage - 2 || pageIndex === currentPage - 1 || pageIndex === currentPage || pageIndex === currentPage + 1) {
    return (
      <PageContainer data-page={pageIndex} >
        <Page
          renderTextLayer={true/* handTool && false */}
          width={widthAlignment ? scrollContainerRef.current?.offsetWidth! - 33 : undefined}
          height={!widthAlignment ? defaultHeight - 18 : undefined}
          scale={scale}
          loading={null}
          pageNumber={pageIndex + 1}
          onLoadSuccess={(page) => (!pageSize) && onSizeUpdate(page.height, page.width)}
        /* onGetAnnotationsSuccess={(annotations) => console.log(annotations)} */
        />
      </PageContainer>
    )
  }

  return (
    <PageContainer data-page={pageIndex} rotate={rotate}>
      <Placeholder
        width={pageSize ? pageSize.width * scale : '36vw'}
        height={pageSize ? pageSize.height * scale : defaultHeight - 18}
      />
    </PageContainer>
  )
})